import { ChakraProvider, ColorModeScript, useToken } from '@chakra-ui/react'
import type { AppProps } from 'next/app'
import theme from '@app/theme'
import Head from 'next/head'
import Layout from '@app/components/Layout'
import GlobalProgressBar from '@app/components/GlobalProgressBar'

function MyApp({ Component, pageProps }: AppProps) {
  return (
    <ChakraProvider resetCSS theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <GlobalProgressBar />
      <Head>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </Head>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </ChakraProvider>
  )
}

export default MyApp
