import { Flex, Text, Icon, Heading } from '@chakra-ui/react'
import SnipedSVG from '@app/assets/sniped.svg'

const Logo = () => {
  return (
    <Flex alignItems="center">
      <Icon as={SnipedSVG} mr="0.2em" width="1.25em" height="1.25em" />
      <Text fontWeight="bold">Sniped</Text>
    </Flex>
  )
}

export default Logo
