import {
  ButtonGroup,
  Container,
  Box,
  Link,
  IconButton,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import * as React from 'react'
import { FaTwitter, FaDiscord } from 'react-icons/fa'
import Logo from './Logo'

export const Footer = () => (
  <Box
    as="footer"
    role="contentinfo"
    py={{ base: '12', md: '16' }}
    width="100%"
    bg={useColorModeValue('gray.100', 'blackAlpha.700')}
  >
    <Container w="full" maxW="container.xl">
      <Stack spacing="4">
        <Stack justify="space-between" direction="row" align="center">
          <Box fontSize="3xl">
            <Logo />
          </Box>
          <ButtonGroup variant="ghost">
            <IconButton
              as="a"
              href="https://discord.com/invite/MR2Cn929wQ"
              target="_blank"
              aria-label="Discord"
              icon={<FaDiscord fontSize="1.25rem" />}
            />
            <IconButton
              as="a"
              href="https://twitter.com/SuperSeaNFT"
              target="_blank"
              aria-label="Twitter"
              icon={<FaTwitter fontSize="1.25rem" />}
            />
          </ButtonGroup>
        </Stack>
        <Box>
          <Text
            fontSize="md"
            color={useColorModeValue('gray.700', 'gray.400')}
            mb="3"
          >
            By{' '}
            <Link
              target="_blank"
              href="https://nonfungible.tools"
              fontWeight="bold"
            >
              Non-Fungible Tools
            </Link>{' '}
            (creators of SuperSea)
          </Text>
          <Link
            href="https://nonfungible.tools/terms"
            target="_blank"
            fontSize="sm"
            fontWeight="bold"
          >
            Terms &amp; Privacy Policy
          </Link>
        </Box>
      </Stack>
    </Container>
  </Box>
)

export default Footer
