import { extendTheme } from '@chakra-ui/react'
import { createBreakpoints } from '@chakra-ui/theme-tools'
import '@fontsource/montserrat/700.css'
import '@fontsource/cabin/400.css'
import '@fontsource/cabin/500.css'
import '@fontsource/cabin/600.css'
import '@fontsource/cabin/700.css'
import '@fontsource/cabin/400-italic.css'

const fonts = {
  mono: `'Menlo', monospace`,
  heading: `'Montserrat', sans-serif`,
  body: `'Cabin', sans-serif`,
}

const breakpoints = createBreakpoints({
  xs: '30em',
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
})

const theme = extendTheme({
  fonts,
  breakpoints,
  config: {
    initialColorMode: 'light',
    useSystemColorMode: false,
  },
})

export default theme
