import { Container, Flex } from '@chakra-ui/react'
import Header from '@app/components/Header'
import Footer from './Footer'

const Layout = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <Flex minH="100vh" flexDirection="column">
      <Container w="full" maxW="container.xl" pb="140px" flex="1">
        {children}
      </Container>
      <Footer />
    </Flex>
  )
}

export default Layout
