import { useToken } from '@chakra-ui/react'
import NextProgressBar from 'nextjs-progressbar'

const GlobalProgressBar = () => {
  return (
    <NextProgressBar
      color={useToken('colors', 'green.300')}
      startPosition={0.3}
      stopDelayMs={200}
      height={5}
      options={{ showSpinner: false }}
    />
  )
}

export default GlobalProgressBar
